import React, { Component, Suspense, lazy } from "react";
import { Router, Route, Link, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./App.css";
import Routes from "./Constants/Routes";
import { Translate, Localize, I18n } from "react-redux-i18n";
import Locale from "./Constants/Locale/Home";
// import Today from "./Layout/Today";
import { fetchLanguageQuran } from "./actions/UserAction";

import Sajdhas from "./Layout/Sajdhas";

import About from "./Layout/About";
import MenuBar from "./Component/Menubar";

import NotFound from "./NotFound/NotFound";

import { createBrowserHistory } from "history";
import { CircularProgress } from "@material-ui/core";
const history = createBrowserHistory();
// const Today = asyncComponent(() =>
//   import("./Layout/Today").then((module) => module.default)
// );
const Today = lazy(() => import("./Layout/Today"));

const YaseenView = lazy(() => import("./Layout/Yaseen"));
const Names = lazy(() => import("./Layout/Names"));
const KursiView = lazy(() => import("./Layout/Kusrsi"));
const DuasView = lazy(() => import("./Layout/Duas"));
const ViewDua = lazy(() => import("./Layout/ViewDua"));
const Quran = lazy(() => import("./Layout/Quran"));
const ViewQuran = lazy(() => import("./Layout/ViewQuran"));
const Dictionary = lazy(() => import("./Layout/Dictionary"));
const Hadith = lazy(() => import("./Layout/Hadith"));
const ViewHadith = lazy(() => import("./Layout/ViewHadith"));
const Kalima = lazy(() => import("./Layout/Kalima"));
const ViewKalima = lazy(() => import("./Layout/ViewKalima"));
const BasicQuran = lazy(() => import("./Layout/BasicQuran"));
const IslamicGallery = lazy(() => import("./Layout/IslamicGallery"));
const Privacy=lazy(()=> import ("./Layout/Privacy"));

class App extends Component {
  constructor(props) {
    super(props);
    console.log("====================================");
    console.log(props);
    console.log("====================================");
    this.state = {
      loading: true,
    };
    setTimeout(this.closeLoader, 3000);
    this.checkLanguage();
    localStorage.setItem("page", 0);
    // I18n.setLocale(localStorage.getItem("language"));
    if (!this.props.isLanguageFetch) {
      this.props.fetchLanguageQuran();
    }
  }
  checkLanguage = () => {
    if (localStorage.getItem("language") === null || localStorage.getItem("language") === "undefined") {
      localStorage.setItem("language", "English");
    }
    if (localStorage.getItem("translation") === null) {
      // localStorage.setItem("translation", "english2.json");
    }
  };
  closeLoader = () => {
    this.setState({ loading: false });
  };
  render() {
    return (
      <div>
        <Router history={history}>
          <Suspense fallback={<div className="center">  <CircularProgress/>
          
          <h6>Loading...</h6>
          </div >}>
            <Switch>
            
            <Route exact path="/" component={Today} />
              <Route exact path={Routes.today} component={Today} />
              <Route exact path={Routes.names} component={Names} />
              <Route exact path={Routes.yaseen} component={YaseenView} />
              <Route exact path={Routes.kursi} component={KursiView} />
              <Route exact path={Routes.duas} component={DuasView} />
              <Route exact path={Routes.viewdua} component={ViewDua} />
              <Route exact path={Routes.quran} component={Quran} />
              <Route exact path={Routes.viewquran} component={ViewQuran} />
              <Route exact path={Routes.dictionary} component={Dictionary} />
              <Route exact path={Routes.hadith} component={Hadith} />
              <Route exact path={Routes.viewhadith} component={ViewHadith} />
              <Route exact path={Routes.kalima} component={Kalima} />
              <Route exact path={Routes.viewkalima} component={ViewKalima} />
              <Route exact path={Routes.basicquran} component={BasicQuran} />
              <Route
                exact
                path={Routes.islamicgallery}
                component={IslamicGallery}
              />
              <Route exact path={Routes.sajdhas} component={Sajdhas} />
              <Route exact path={Routes.about} component={About} />
              <Route exact path={Routes.privacy} component={Privacy} />
              <Route path="*" component={NotFound} />
              
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLanguageFetch: state.user.isLanguageFetch,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchLanguageQuran: fetchLanguageQuran,
    },
    dispatch
  );
}

var connectFile = connect(mapStateToProps, matchDispatchToProps)(App);

export { connectFile as App };
