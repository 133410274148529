import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Language from "./SubComponent/Language"
import { I18n, Translate } from 'react-redux-i18n';
import Config from "../Constants/Constants"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Setting from "./Setting"
import SettingsIcon from '@material-ui/icons/Settings';
import Routes from "../Constants/Routes"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: "14px"
  },
  appbar: {
    background: "#1976d2",
    color: "white"
  }
}));

export default function MenuAppBar(props) {
  const classes = useStyles();


  const checkEntry = () => {
    const historyTrack = {
      "duas": "",
      "babynames": "more",
      "gendernames": "babynames",
      "quran": "",
      "viewquran": "quran",
      "setting": "viewquran",
      "viewdua": "duas",
      "names": "more",
      "quiz": "more",
      "yaseen": "more",
      "kursi": "more",
      "dictionary": "more",
      "viewhadith": "hadith",
      "hadith": "more",
      "quizresult": "quiz",
      "viewquiz": "quiz",
      "viewkalima": "kalima",
      "kalima": "more",
      "basicquran": "more",
      "islamicgallery": "more",
      "sajdhas": "setting",
      "sharetoday": "",
      "about": "appsetting"
      // "appsetting":"more"
    }
    const routeValue = window.location.hash.replace("#/", "");
    if (window.location.hash === "#/appsetting") {
      window.location.href = localStorage.getItem("historypage")
      return
    }
    if (routeValue.includes("shareimage")) {
      window.location.href = localStorage.getItem("sharefeature")
    }

    else {
      window.location.href = `#/${historyTrack[routeValue]}`
    }

  }


  const goback = () => {
    checkEntry()
    // window.location.href=window.location.origin+window.location.pathname+"#/"
  }
  const settingDirect = () => {
    window.location.href = Routes.route.setting
  }

  const settingApp = () => {
    window.location.href = Routes.route.appsetting
  }
  if (window.location.hash !== "#/appsetting" && window.location.hash !== "#/about") {
    localStorage.setItem("historypage", window.location.hash)
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
          {props.enableBack ?
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <ArrowBackIcon onClick={goback} />
            </IconButton>
            :
            null
          }
          <Typography variant="h6" className={classes.title}>
            {props.name}
          </Typography>
          <Button color="inherit">
          </Button>
          {/* {window.location.hash === "#/viewquran" ?
            <SettingsIcon onClick={settingDirect} />
            :
            <SettingsIcon onClick={settingApp} />
          } */}
        </Toolbar>
      </AppBar>
    </div>
  );
}
