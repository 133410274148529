const route = {
        homeview: "/homeview",
        setting: "/setting",
        notification: "/notification",
        names: '/99namesofallah/:language',
        yaseen: "/surah-yaseen/:language",
        shareimage: "/shareimage",
        kursi: "/ayat-ul-kursi/:language",
        duas: "/duas/:language",
        viewdua: "/duas/:language/:duas/:number",
        quiz: "/islamicquiz",
        quran: "/quran/:language",
        viewquran: "/quran/:language/:surah/:surahNumber/:ayatnumber",
        more: "/more",
        dictionary: "/dictionary",
        setting: "/setting",
        hadith: "/hadith/:language",
        viewhadith: "/hadith/:language/:hadithtitle",
        kalima: "/kalima/:language",
        viewkalima: "/kalima/:language/:title/:index",
        basicquran: "/basicquran/:language",
        today: "/:language",
        islamicgallery: "/islamicgallery/:language",
        appsetting: "/appsetting",
        sajdhas: "/sajdhas",
        sharetoday: "/sharetoday",
        about: "/about",
        home: "/",
        privacy: "/privacypolicy/:language",
        route: {
                names: "/99namesofallah",
                homeview: "/",
                yaseen: "/surah-yaseen",
                quran: "/quran",
                duas: "/duas",
                kursi: "/ayat-ul-kursi",
                viewDua: "/duas",
                more: "/more",
                setting: "/setting",
                viewhadith: "/hadith",
                viewquiz: "/viewquiz",
                quizresult: "/quizresult",
                viewkalima: "/kalima",
                today: "/",
                appsetting: "/appsetting",
                sajdhas: "/sajdhas",
                quiz: "/islamicquiz",
                viewquran: "/quran",
                dictionary: "/dictionary",
                hadith: "/hadith",
                kalima: "/kalima",
                islamicgallery: "/islamicgallery",
                basicquran: "/basicquran",
                privacy: "/privacypolicy/:language",
        
        }
}
export default route