import React from 'react';
import iconImage from "./Image/Icon"
import playStoreIcon from "./Image/playstore"
const Config = {
  name: {
    supportLanguage: ["English", "Tamil", "Urdu"]
  },
  home: {
    supportLanguage: ["English", "Tamil", "Urdu"]
  },
  iconImage: iconImage,
  playStoreImage: playStoreIcon,
  appLink: "https://play.google.com/store/apps/details?id=islam.zain.alif",
  appGallery: "Assalamu alaikum ! I am sharing this quotes from Alif app. If you want to share amazing quotes like these, then click on the following link:",
  appMessageTemplate: "Assalamu alaikum ! I am sharing this MESSAGE from Alif app. If you want to share amazing MESSAGE like these, then click on the following link:",
  language: [
    {
      id: "English",
      label: "English"
    },
    {
      id: "Tamil",
      label: "தமிழ்"
    },
    {
      id: "Urdu",
      label: "اردو"
    }
  ],
  reciter: [
    { id: "English", label: "Mishary Rashed Alafasy(English)" },
    { id: "Tamil", label: "Mahmoud Khalil Al-Husary(Tamil)" },
    { id: "Urdu", label: "MISHARI BIN RASHID AL AFASI (Urdu)" },
  ],
  translation: [


    {
      id: "English.json",
      label: " English (Ahmed Ali)"
    },
    {
      id: "Tamil.json",
      label: " Tamil (Jan Turst)"
    },
    {
      id: "Urdu.json",
      label: "Urdu (Abul A'ala Maududi)"
    },

  ],
  selectscript: [
    {
      id: "uthmani",
      label: "Uthmani"
    },
    {
      id: "naskharabic",
      label: "Naskh Arabic"
    }
  ],
  beginSurah: {
    arabic: "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
    English: "In the name of Allah, the Beneficent, the Merciful.",
    Urdu: "شروع الله کا نام لے کر جو بڑا مہربان نہایت رحم والا ہے",
    Tamil: "அளவற்ற அருளாளன், நிகரற்ற அன்பாளன், அல்லாஹ்வின் பெயரால்! (தொடங்குகிறேன்).",
    EnglishMeaning: "Bismi Allahi alrrahmani alrraheemi"

  },

  QUIZ: {
    level: ["level1", "level2", "level3", "level4", "level5", "level6"]
  },
  importantSurah: [1, 55, 56, 48, 36, 19, 12, 18],
  ENGLISH: "English",
  TAMIL: "Tamil",
  URDU: "Urdu",
  TAMILMEANING: "TamilMeaning",
  ENGLISHMEANING: "EnglishMeaning",
  URDUMEANING: "UrduMeaning",
  supportLanguage: ["English", "Tamil", "Urdu"],
  MALE: "MALE",
  FEMALE: "FEMALE",
  // url: "http://localhost:8000/data",
  url: "https://aliflearning.in",
  urldata: "https://aliflearning.in"
  // urldata: "http://localhost:8000/"
}

export default Config;
