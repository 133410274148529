import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SettingsIcon from '@material-ui/icons/Settings';
import Language from "./SubComponent/Language"
import {I18n,Translate} from 'react-redux-i18n';
import Config from "../Constants/Constants"
const useStyles = makeStyles({
  list: {
    width: 250,
    background:"whitesmoke"
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const selectLanguage=(lang)=>{
    console.log(lang);
    if(Config.supportLanguage.includes(lang)){
      localStorage.setItem("language",lang)
      I18n.setLocale(lang)
    }

    if(props.hasOwnProperty("selectLanguage")){
          props.selectLanguage(lang)
    }
    if(window.location.hash!="#/"){
      // window.location.href=window.location.origin+window.location.pathname+window.location.hash
    }        
}

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
            <Language  selectLanguage={selectLanguage} language={props.language}/>
      <List>
      
      </List>
    </div>
  );

  return (
    <div>
        <React.Fragment key={"right"}>
            <SettingsIcon onClick={toggleDrawer('right', true)}/>
          <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
            {list("right")}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
