import axios from "axios";
import ReducerConstant from "../Constants/ReducerConstant.js"
import Config from "../Constants/Constants.js"
import $ from 'jquery';
import { dispatch } from "d3";

export function viewDuas(params) {
  return function (dispatch) {
    axios({
      method: 'post',
      url: Config.apiPoints.login,
      data: params,
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      responseType: 'json',
      withCredentials: true, // default
    })
      .then(function (response) {
        dispatch({ type: ReducerConstant.SIGNUP_COMPLETED, payload: response.data })
      })
      .catch(function (error) {
        dispatch({ type: ReducerConstant.SIGNUP_NOTCOMPLETED, payload: 'Something Went Wrong' })
      })
  }
}

export function fetchLanguageQuran() {
  return function (dispatch) {
    $.getJSON(`${window.location.origin}/Surah/Translation/${localStorage.getItem("language")}.json`, function (data, status) {
      dispatch({
        type: "FETCH_LANGUAGE",
        payload: data.quran.sura
      })
      // self.setState({quranTranslation:data.quran.sura[Number(self.props.user.quranSelected-1)],isFetch:true})
    })
  }
}

export function addAdCounter() {
  return function (dispatch) {
    dispatch({
      type: "ADD_AD_COUNTER",
      payload: 1
    })
  }

}