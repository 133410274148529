import React from "react"
import {
    Paper, Typography, List, ListItem, ListItemIcon, ListItemSecondaryAction, FormControl, MenuItem, Select, ListItemText, Grid, FormControlLabel,
    InputLabel, Switch, Container, ListItemAvatar, Link, Fab
} from "@material-ui/core"
import Appbar from "../Component/Appbar"
import withStyles from '@material-ui/core/styles/withStyles';
import { Translate } from "react-redux-i18n";
import Config from "../Constants/Constants";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { I18n } from 'react-redux-i18n'
import TranslateIcon from '@material-ui/icons/Translate';
import SajdhasData from "./Data/sajdhas.json"
const styles = theme => ({
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'block',
        flexDirection: 'column',
        marginTop: "2px",
        fontFamily: "Scheherazade, Scheheraza!important",
        background: "white",
        "&:hover": {
            background: "whitesmoke",
            color: "black"
        }
    },
    fabIcon: {
        width: "34px",
        height: "30px"
    }

})
class Sajdhas extends React.Component {

    constructor() {
        super()
        this.state = {
            language: localStorage.getItem("language")
        }
    }
    selectLanguage = () => {

    }

    render() {
        const { classes } = this.props
        const { language } = this.state
        return (
            <div>

                <Grid containder spacing={1}>
                    <Grid xs={12}>
                        <Paper className={classes.paper}>
                            <List>
                                {SajdhasData.map((sajdhas, index) => {
                                    return (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Fab className={classes.fabIcon}>
                                                    {index + 1}
                                                </Fab>
                                            </ListItemAvatar>
                                            <Typography>
                                                {sajdhas[language]}
                                            </Typography>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default withStyles(styles)(Sajdhas)
