import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import Config from "../../Constants/Constants"
import { Select, FormControl, FormControlLabel } from '@material-ui/core';
import { I18n, Translate } from 'react-redux-i18n';
import { json } from 'd3';

export default function LanguageMenu(props) {
  console.log(props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState(localStorage.getItem("language"))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lang) => {
    setAnchorEl(null);
  };




  const languageSelect = (event) => {
    console.log(event.target);
    if (Config.supportLanguage.includes(event.target.value)) {
      localStorage.setItem("language", event.target.value)
      I18n.setLocale(event.target.value)
      setLanguage(event.target.value)
    }
    // props.selectLanguage(event.target.value)
  }

  return (
    <div>
      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.language.map(lang=>
        <MenuItem onClick={handleClose.bind(null,lang)}>{lang}</MenuItem>
          )}
      </Menu> */}
      <FormControlLabel
        label={<LanguageIcon style={{ 'font-size': "20px", 'color': 'white' }} />}
        control={
          <Select value={language} onChange={languageSelect} style={{ 'color': "white" }}>
            <MenuItem value="English">
              English
          </MenuItem>
            <MenuItem value="Tamil">
              Tamil
          </MenuItem>
            <MenuItem value="Urdu">
              Urdu
          </MenuItem>

          </Select>

        }
        labelPlacement="left"
      />
      {/* <FormControl variant="outlined">
      </FormControl> */}

    </div>
  );
}
