import React from "react"
import { Typography, Paper, Container } from "@material-ui/core"
import Appbar from "../Component/Appbar"
import { Translate } from "react-redux-i18n";

class About extends React.Component {
    constructor() {
        super()
    }
    render() {
        return (
            <div>

                <div style={{ 'margin-top': "60px" }}></div>
                <Container>

                    <Paper>
                        <Typography component="h6" variant="h6">
                            DISCLAIMER:
                            </Typography>
                        <Typography>
                            This app is a human effort and it was developed with the best intentions.Any mistakes in the content is undeliberate and due to sheer humanness.However, if you notice any mistakes or errors please notify us and we will resolve it at the earliest.
                                </Typography>
                        <Typography>
                            The text copy of the Quran and its translation are carefully produced, highly verified and continuously monitored by a group of specialists in the Tanzil project. More info at
                            http://tanzil.net/trans/
                    </Typography>
                        <Typography>
                            The audio recitations of the Quran recorded by Mishary Rahed Alafasy(english),
                            Mahmoud Khalil Al-Husary(tamil) and Mishary Bin Rashid Alafasy(urdu) ,The audio recitation of Asma-ul-Husna are best of our knowledge,in the public domain.

                    </Typography>
                        <Typography>
                            The collection of questions for quiz are taken from www.islamicity.org
                    </Typography>


                    </Paper>
                </Container>

            </div>
        )
    }
}
export default About