import React, { Component } from 'react'
import './NotFound.css'

class NotFound extends Component {
    render () {
        return (
            <div className="container">
                <p>404</p>
                <p>Page not found</p>
            </div>
        )
    }
}

export default NotFound