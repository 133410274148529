import HadithData from "../Layout/Data/Hadith/hadith.json";
import level1 from "../Layout/Data/Quiz/level1.json";
import level2 from "../Layout/Data/Quiz/level2.json";
import level3 from "../Layout/Data/Quiz/level3.json";
import level4 from "../Layout/Data/Quiz/level4.json";
import level5 from "../Layout/Data/Quiz/level5.json";
import level6 from "../Layout/Data/Quiz/level6.json";
import kalima from "../Layout/Data/Kalima/kalima.json";
import alphabet from "../Layout/Data/Alphabets/alphabets.json";
import dictionary from "../Layout/Data/dictionary.json";
import quran from "../Layout/Data/Surah/quran.json";
import englishquran from "../Layout/Data/Surah/English.json";
import Duas from "../Layout/Data/Duas/Duas";
import quranTitle from "../Layout/Data/Surah/entry.json";
import NameAllah from "../Layout/Data/99Names.json";

export default function reducer(
  state = {
    shareImage: "",
    shareImageData: {
      index: "",
      arabic: "",
      english: "",
      language: "",
      title: "",
      length: "",
    },
    selectedDuas: {
      title: "",
      duas: [],
      icon: "",
    },
    quranSelected: "",
    selectedQuran: {
      title: "",
      quran: [],
      icon: "",
      number: "",
      numberBetween: "",
      maxLength: 0,
    },
    hadith: HadithData,
    hadithTitle: {
      English: [...new Set(HadithData.map((obj) => obj.EnglishCategory))],
      Tamil: [...new Set(HadithData.map((obj) => obj.TamilCategory))],
      Urdu: [...new Set(HadithData.map((obj) => obj.UrduCategory))],
    },
    selectedHadith: "",
    babyNames: {
      gender: "",
    },
    quiz: {
      level1: level1,
      level2: level2,
      level3: level3,
      level4: level4,
      level5: level5,
      level6: level6,
      selectedLevel: 0,
      selectedLevelStage: 0,
    },
    quizMark: {},
    kalima: kalima,
    selectedKalima: 0,
    alphabet: alphabet,
    dictionary: dictionary,
    quran: quran.quran.sura,
    englishquran: englishquran.quran.sura,
    languagequran: [],
    duas: Duas,
    duasTitle: Object.keys(Duas),
    quranTitle: quranTitle,
    namesofallah: NameAllah,
    shareFeatureData: {
      children: {},
      quranArabic: "",
      quranEnglish: "",
      quranLanguage: "",
      ayaNumber: "",
      surahNumber: "",
      type: "",
    },
    shareToday: {
      id: "",
    },
    adCounter: 0,
    isLanguageFetch: false,
  },
  action
) {
  switch (action.type) {
    case "FETCH_LANGUAGE": {
      return {
        ...state,
        languagequran: action.payload,
        isLanguageFetch: true,
      };
    }
    case "ADD_AD_COUNTER":
      return {
        ...state,
        adCounter: state.adCounter + action.payload,
      };
  }
  return state;
}
