import { I18n } from 'react-redux-i18n';

const homeLanguage = {
        "English": {
                "home": {
                        nameOfAllah: "Names of allah",
                        duas: "Duas",
                        quran: "Quran",
                        quiz: "Quiz",
                        yaseen: "Yaseen",
                        kursi: "Ayat-ul-Kursi",
                        more: "More",
                        today: "Today",
                        dictionary: "Dictionary",
                        babynames: "Baby Names",
                        hadith: "Hadith",
                        dictionary: "Dictionary",
                        kalima: "Kalima",
                        basicquran: "Arabic Alphabets",
                        islamicgallery: "Islamic Gallery",
                        about: "Disclaimer",
                        home: "Home"
                },
                content: {
                        "title": "Alif Islamic Learning",
                        share: "Share",
                        noInternet: "An error has occurred while retrieving content from the internet . Please check your connection and try again",
                        view: "View",
                        popularsurah: "Popular Quranic Surarh and verses"
                },
                yaseen: {
                        "meaning": "Translation"
                },
                shareimage: {
                        "surah": "Surah"
                },
                setting: {
                        reciter: "Reciter",
                        translation: "Translation",
                        selectscript: "Select Script",
                        transliteration: "Transliteration",
                        stopsign: "Stop Sign",
                        sajhdas: "Sajhdas",
                        setting: "Setting"
                }
                ,
                welcome: {
                        selectlanguage: "Select the language",
                        languageapp: "Language of the app"

                },
                quiz: {
                        level1: "Level 1",
                        level2: "Level 2",
                        level3: "Level 3",
                        level4: "Level 4",
                        level5: "Level 5",
                        level6: "Level 6",
                        level: "Level",
                        takequiz: "Take Quiz",
                        correctanswer: "Correct answer",
                        wronganswer: "Wrong answer",
                        quizresult: "Quiz result",
                        exit: "Exit",
                        grade: "Grade",
                        selectlevel: "Select the level"
                },
                takequiz: {
                        "1": "A",
                        "2": "B",
                        "3": "C",
                        "4": "D"
                },
                kalima: {
                        kalima: "Kalima"
                },
                today: {
                        hadithday: "Hadith of the day",
                        wordday: "Word of the day",
                        ayatday: "Ayat of the day",
                        duaday: "Dua of the day",
                        namesofallah: "Asma-ul-husna",
                        quizanswer: "You have already answered",
                        todayquiz: "Today quiz"
                },
                quran: {
                        onlinestreaming: " Play online ",
                        downloadstreaming: "Download and stream",
                        downloading: "Downloading",
                        downloadcompleted: "Download completed"
                },
                islamicgallery: {
                        offline: "No internet connection",
                        online: "Internet connection established"
                }
        },
        "Tamil": {
                "home": {
                        nameOfAllah: "அல்லாஹ்வின் பெயர்கள்",
                        duas: "துவாஸ்",
                        quran: "குர்ஆன்",
                        quiz: "வினாடி வினா",
                        yaseen: "யாசின்",
                        kursi: "அயத்-உல்-குர்சி",
                        more: "மேலும்",
                        today: "இன்று",
                        babynames: "குழந்தை பெயர்கள்",
                        hadith: "ஹதீஸ்",
                        dictionary: "அகராதி",
                        kalima: "கலிமா",
                        basicquran: "அரபு எழுத்துக்கள",
                        islamicgallery: "இஸ்லாமிய தொகுப்பு",
                        about: "மறுப்பு",
                        home:"முகப்பு பக்கம்"
                },
                content: {
                        title: "அலிஃப் இஸ்லாமிய கற்றல் பயன்பாடு",
                        share: "பகிர்",
                        noInternet: "இணையத்திலிருந்து உள்ளடக்கத்தை மீட்டெடுக்கும்போது பிழை ஏற்பட்டது. உங்கள் இணைப்பைச் சரிபார்த்து மீண்டும் முயற்சிக்கவும்",
                        view: "பார்வை",
                        popularsurah: "பிரபலமான குர்ஆன் சூரர் மற்றும் வசனங்கள்"
                },
                yaseen: {
                        "meaning": "மொழிபெயர்ப்பு"
                },
                shareimage: {
                        "surah": "சூரா"
                },
                setting: {
                        reciter: "ஓதுவார்",
                        translation: "மொழிபெயர்ப்பு",
                        selectscript: "ஸ்கிரிப்டைத் தேர்ந்தெடுக்கவும்",
                        transliteration: "ஒலிபெயர்ப்பு",
                        stopsign: "நிறுத்தல் குறி",
                        sajhdas: "சஜ்தாஸ்",
                        setting: "அமைப்பு"
                }
                ,
                welcome: {
                        selectlanguage: "மொழியைத் தேர்ந்தெடுக்கவும்",
                        languageapp: "பயன்பாட்டின் மொழி"


                },
                quiz: {
                        level1: "நிலை 1",
                        level2: "நிலை 2",
                        level3: "நிலை 3",
                        level4: "நிலை 4",
                        level5: "நிலை 5",
                        level6: "நிலை 6",
                        level: "நிலை",
                        takequiz: "வினாடி வினா எடுக்கவும்",
                        correctanswer: "சரியான பதில்",
                        wronganswer: "தவறான பதில்",
                        quizresult: "வினாடி வினா முடிவு",
                        exit: "வெளியேறு",
                        grade: "தரம்",
                        selectlevel: "நிலை தேர்ந்தெடுக்கவும்"

                },
                takequiz: {
                        "1": "அ",
                        "2": "பி",
                        "3": "சி",
                        "4": "டி"
                },
                kalima: {
                        kalima: "கலிமா"
                },
                today: {
                        hadithday: "இன்றைய ஹதீஸ்",
                        wordday: "இன்றைய சொல்",
                        ayatday: "இன்றைய ஆயத்",
                        duaday: "இன்றைய துவா",
                        namesofallah: "அஸ்மா-உல்-ஹுஸ்னா",
                        quizanswer: "நீங்கள் ஏற்கனவே பதிலளித்துள்ளீர்கள்",
                        todayquiz: "இன்று வினாடி வினா"
                }
                ,
                quran: {
                        onlinestreaming: "ஆன்லைன் ஸ்ட்ரீமிங்",
                        downloadstreaming: "பதிவிறக்கி ஸ்ட்ரீம் செய்க",
                        downloading: "பதிவிறக்குகிறது",
                        downloadcompleted: "பதிவிறக்கம் முடிந்தது"
                },
                islamicgallery: {
                        offline: "இணைய இணைப்பு இல்லை",
                        online: "இணைய இணைப்பு நிறுவப்பட்டது"
                }
        },
        "Urdu": {
                "home": {
                        nameOfAllah: "اللہ کے نام",
                        duas: "دعا",
                        quran: "قران",
                        quiz: "کوئز",
                        yaseen: "یاسین",
                        kursi: "آیت الکرسی",
                        more: "مزید",
                        today: "آج",
                        babynames: "بچے کے نام",
                        hadith: "حدیث",
                        dictionary: "لغت",
                        kalima: "کلمہ",
                        basicquran: "عربی حروف",
                        islamicgallery: "اسلامی گیلری",
                        about: "دستبرداری"
                },
                content: {
                        title: "الیف اسلامی لرننگ ایپ",
                        share: "بانٹیں",
                        noInternet: "انٹرنیٹ سے مواد بازیافت کرتے وقت ایک خرابی پیش آگئی۔ براہ کرم اپنا کنکشن چیک کریں اور دوبارہ کوشش کریں",
                        view: "دیکھیں",
                        popularsurah: "مشہور قرآنی سورہ اور آیات"
                },
                yaseen: {
                        "meaning": "ترجمہ"
                },
                shareimage: {
                        "surah": "سور."
                },
                setting: {
                        reciter: "تلاوت کرنے والا",
                        translation: "ترجمہ",
                        selectscript: "اسکرپٹ منتخب کریں",
                        transliteration: "نقل حرفی",
                        stopsign: "رکنے کا نشان",
                        sajhdas: "ساجداس",
                        setting: "ترتیب"
                }
                ,
                welcome: {
                        selectlanguage: "زبان کا انتخاب کریں",
                        languageapp: "ایپ کی زبان"
                },
                quiz: {
                        level1: "سطح 1",
                        level2: "سطح 2",
                        level3: "سطح 3",
                        level4: "سطح 4",
                        level5: "سطح 5",
                        level6: "سطح 6",
                        level: "سطح",
                        takequiz: "کوئز لیں",
                        correctanswer: "درست جواب",
                        wronganswer: "غلط جواب",
                        quizresult: "کوئز نتیجہ",
                        exit: "باہر نکلیں",
                        grade: "گریڈ",
                        selectlevel: "سطح منتخب کریں"

                }
                ,
                takequiz: {
                        "1": "A",
                        "2": "B",
                        "3": "C",
                        "4": "D"
                },
                kalima: {
                        kalima: "کلمہ"
                },
                today: {
                        hadithday: "آج کی حدیث",
                        wordday: "آج کا لفظ",
                        ayatday: "آج کی آیت",
                        duaday: "آج کی دعا",
                        namesofallah: "اسماء الحسنہ",
                        quizanswer: "آپ جواب دے چکے ہیں",
                        todayquiz: "آج کوئز"

                },
                quran: {
                        onlinestreaming: "آن لائن سلسلہ بندی",
                        downloadstreaming: "ڈاؤن لوڈ اور اسٹریم کریں",
                        downloading: "ڈاؤن لوڈ",
                        downloadcompleted: "ڈاؤن لوڈ مکمل"
                }
                ,
                islamicgallery: {
                        offline: "انٹرنیٹ کنکشن نہیں ہے",
                        online: "انٹرنیٹ کنکشن قائم ہے"
                }
        }
}

I18n.setTranslations(homeLanguage)