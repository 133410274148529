import { Translate } from 'react-redux-i18n';
import React from "react"
import Routes from "../Constants/Routes"
import duas from "../images/icon/duas.png"
import names from "../images/more/Allah.png"
import quiz from "../images/more/quiz.png"
import quran from "../images/icon/quran.png"
import yaseen from "../images/more/yaseen.png"
import kursi from "../images/more/kursi.png"
import babyImage from "../images/more/babyNames.png"
import dictionary from "../images/more/dictionary.png"
import hadith from "../images/more/hadith.png";
import kalima from "../images/more/kalima.png"
import basicQuran from "../images/more/basicQuran.png"
import gallery from "../images/more/gallery.png"
import home from "../images/more/home.png"
import TodayIcon from '@material-ui/icons/Today';



const menulist = [
    {
        "name": <Translate value="home.today" />,
        url: `/LANGUAGE`,
        "u_name": "Today",
        "image": home
    },
    {
        "name": <Translate value="home.quran" />,
        url: `/quran/LANGUAGE`,
        "u_name": "Al Quran",

        "image": quran
    },
    {
        "name": <Translate value="home.duas" />,
        url: `/duas/LANGUAGE`,
        "u_name": "Islamic Duas",

        "image": duas
    },
    {
        "name": <Translate value="home.nameOfAllah" />,
        "color": "whitesmoke",
        "u_name": "Asma-ul-husna",

        "url": `/99namesofallah/LANGUAGE`,
        "image": names
    },

    {
        "name": <Translate value="home.yaseen" />,
        "color": "whitesmoke",
        "u_name": "Today",

        "url": `/surah-yaseen/LANGUAGE`,
        "image": yaseen
    },
    {
        "name": <Translate value="home.kursi" />,
        "color": "whitesmoke",
        "u_name": `Ayat-ul-Kursi`,

        "url": `/ayat-ul-kursi/LANGUAGE`,
        "image": kursi
    },
    {
        "name": <Translate value="home.hadith" />,
        "color": "whitesmoke",
        "url": `/hadith/LANGUAGE`,
        "u_name": "Hadith",

        "image": hadith
    },
    {
        "name": <Translate value="home.kalima" />,
        "color": "whitesmoke",
        "u_name": "Today",

        "url": `/kalima/LANGUAGE`,
        "image": kalima
    },
    {
        "name": <Translate value="home.basicquran" />,
        "color": "whitesmoke",
        "u_name": "Kalima",

        "url": `/basicquran/LANGUAGE`,
        "image": basicQuran
    },
    ,
    {
        "name": <Translate value="home.islamicgallery" />,
        "color": "whitesmoke",
        "u_name": "Islamic Gallery",
        "url": `/islamicgallery/LANGUAGE`,
        "image": gallery
    }
]
export default menulist